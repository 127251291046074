const Footer = () => {
  return (
    <footer id="contact" className="scrollto animated superslow">
      <a href="http://blog.gregcoffman.com" target="_blank" rel="noreferrer">
        Blog
      </a>{" "}
      |{" "}
      <a
        href="http://www.linkedin.com/in/gregcoffman/"
        target="_blank"
        rel="noreferrer"
      >
        LinkedIn
      </a>{" "}
      |{" "}
      <a
        href="http://www.facebook.com/gregacoffman"
        target="_blank"
        rel="noreferrer"
      >
        Facebook
      </a>{" "}
      |{" "}
      <a
        href="https://github.com/gregcoffman/"
        target="_blank"
        rel="noreferrer"
      >
        Github
      </a>{" "}
      |{" "}
      <a href="mailto:greg@gregcoffman.com" target="_blank" rel="noreferrer">
        greg@gregcoffman.com
      </a>
    </footer>
  );
}

export default Footer;
