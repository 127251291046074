import Canvas from "./Canvas";

const Hero = () => {
  return (
    <section style={{ backgroundColor: "#FFFCEF" }}>
      <Canvas />
    </section>
  );
}

export default Hero;
