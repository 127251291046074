import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

import AshleyThumb from "../assets/images/ashley.jpg";
import AshleyLg from "../assets/images/ashley.lg.jpg";
import DuomoThumb from "../assets/images/duomo.jpg";
import DuomoLg from "../assets/images/duomo.lg.jpg";
import LemonGrassThumb from "../assets/images/lemongrass.jpg";
import LemonGrassLg from "../assets/images/lemongrass.lg.jpg";
import LianaThumb from "../assets/images/liana.jpg";
import LianaLg from "../assets/images/liana.lg.jpg";
import LianaCafeThumb from "../assets/images/lianacafe.jpg";
import LianaCafeLg from "../assets/images/lianacafe.lg.jpg";
import NMThumb from "../assets/images/nm.jpg";
import NMLg from "../assets/images/nm.lg.jpg";
import ReanaThumb from "../assets/images/reana.jpg";
import ReanaLg from "../assets/images/reana.lg.jpg";
import SepticThumb from "../assets/images/septic.jpg";
import SepticLg from "../assets/images/septic.lg.jpg";
import YingThumb from "../assets/images/ying.jpg";
import YingLg from "../assets/images/ying.lg.jpg";

const images = [
  AshleyLg,
  DuomoLg,
  LemonGrassLg,
  LianaLg,
  LianaCafeLg,
  NMLg,
  ReanaLg,
  SepticLg,
  YingLg,
];
const thumbs = [
  AshleyThumb,
  DuomoThumb,
  LemonGrassThumb,
  LianaThumb,
  LianaCafeThumb,
  NMThumb,
  ReanaThumb,
  SepticThumb,
  YingThumb,
];

const titles = [
  "Ash Squirrel",
  "Duomo",
  "LemonGrass Restaurant Ad",
  "Nilda",
  "The Cafe",
  "Las Cruces, NM Postcard",
  "Reana",
  "Septic System Installation Comic Book",
  "Ying"
];


const Illustrations = () => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = React.useState(0);
    return (
      <section id="illustrations">
        <img
          src={thumbs[0]}
          alt={titles[0] + " thumbnail"}
          onClick={() => {setIndex(0); setOpen(true)}}
        />
        <img
          src={thumbs[1]}
          alt={titles[1] + " thumbnail"}
          onClick={(e) => {setIndex(1); setOpen(true)}}
        />
        <img
          src={thumbs[2]}
          alt={titles[2] + " thumbnail"}
          onClick={(e) => {setIndex(2); setOpen(true)}}
        />
        <img
          src={thumbs[3]}
          alt={titles[3] + " thumbnail"}
          onClick={(e) => {setIndex(3); setOpen(true)}}
        />
        <img
          src={thumbs[4]}
          alt={titles[4] + " thumbnail"}
          onClick={(e) => {setIndex(4); setOpen(true)}}
        />
        <img
          src={thumbs[5]}
          alt={titles[5] + " thumbnail"}
          onClick={(e) => {setIndex(5); setOpen(true)}}
        />
        <img
          src={thumbs[6]}
          alt={titles[6] + " thumbnail"}
          onClick={(e) => {setIndex(6); setOpen(true)}}
        />
        <img
          src={thumbs[7]}
          alt={titles[7] + " thumbnail"}
          onClick={(e) => {setIndex(7); setOpen(true)}}
        />
        <img
          src={thumbs[8]}
          alt={titles[8] + " thumbnail"}
          onClick={(e) => {setIndex(8); setOpen(true)}}
        />

        <Lightbox
        open={open}
        index={index}
        close={() => setOpen(false)}
        slides={[
          { src: images[0], alt: titles[0], title: titles[0], width: 411, height: 600 },
          { src: images[1], alt: titles[1], title: titles[1], width: 464, height: 600 },
          { src: images[2], alt: titles[2], title: titles[2], width: 600, height: 456 },
          { src: images[3], alt: titles[3], title: titles[3], width: 600, height: 462 },
          { src: images[4], alt: titles[4], title: titles[4], width: 600, height: 448 },
          { src: images[5], alt: titles[5], title: titles[5], width: 800,  height: 533 },
          { src: images[6], alt: titles[6], title: titles[6], width: 600, height: 382 },
          { src: images[7], alt: titles[7], title: titles[7], width: 397, height: 600 },
          { src: images[8], alt: titles[8], title: titles[8], width: 538, height: 600 }
        ]}
        plugins={[Captions]}
      />
      </section>
    );
  }

export default Illustrations;
