import Illustrations from "./Illustrations";

const Work = () => {
  return (
    <>
      <Illustrations />
    </>
  );
}

export default Work;
