import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Header = () => {
  return (
    <BrowserRouter>
    <Routes>
    <Route
    exact
    path="/"
    element={
      <header>
        <nav className="pageScrollerNav" role="navigation">
          <NavHashLink smooth to="#about">
            About
          </NavHashLink>
          <NavHashLink smooth to="#work">
            Work
          </NavHashLink>
          <NavHashLink smooth to="#contact">
            Contact
          </NavHashLink>
        </nav>
      </header>
    }
  />
</Routes> 
</BrowserRouter>
  );
}

export default Header;
