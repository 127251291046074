import Header from "./components/Header";
import CanvasHero from "./components/CanvasHero";
import Work from "./components/Work";
import Footer from "./components/Footer";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useState, useEffect } from "react";

import './App.css';

const query = `
{
  homepageCollection {
    items {
      pageTitle
      hpWorkText {
        json
      }
      hpBlurbText {
        json
      }
    }
  }
}
`;

function App(root) {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/uvhuifrygzd5/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer z7JJkFH70Wh_ZQXCL6Azqjcfc8RbCDOt_zPC4LVoPQo", 
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        } 
        // rerender the entire component with new data
        setPage(data.homepageCollection.items[0]);

      })
  }, []);
  if (!page) {
    return "Loading...";
  }

  return (
    <div className="App">
      <Header />
      <CanvasHero />
      <section id="about">
      {documentToReactComponents(page.hpBlurbText.json)}
      </section>
      <section id="work">
        <div id="workblurb">{documentToReactComponents(page.hpWorkText.json)}</div>
        <Work />
      </section>
      <Footer />
    </div>
  );
}

export default App;
