import { useState } from 'react';
import { AnimateCC, GetAnimationObjectParameter } from 'react-adobe-animate';

const Canvas = () => {
  const [animationObject, getAnimationObject] = useState<GetAnimationObjectParameter|null>(null);

  console.log(animationObject);

  return (
    <div id="hero" style={{ width: "100%", 
                  maxWidth: "1200px",
                  margin: "auto"
                  }}>
      <AnimateCC
        animationName="Canvas"
        getAnimationObject={getAnimationObject}
        composition="5A9B57F377D38F4AA4808B9CEEC4B2B7"
      />
    </div>
  );
};

export default Canvas;